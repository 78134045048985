import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getClassesBySchoolId,
  getClassAssessmentsForMultipleClasses,
} from "../services/APIClient";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import OrientationPrompt from "../components/OrientationPrompt";
import SencoResultsClassOverview from "../components/SencoResultsClassOverview";
import { Container, Row, Col } from "react-bootstrap";
import "../components/ClassOverview.css";

function SencoResultDetail(props) {
  const location = useLocation();
  let schoolid = location?.state?.schoolid;
  let teacherid = location?.state?.teacherid;
  let name = location.state.name.name;
  let teachers = location.state.teachers.teachers;
  let gradeLevel = location.state?.gradeLevel?.gradeLevel;

  const [classes, setClasses] = useState([]);
  const [classIds, setClassIds] = useState([]);
  const [allClassesAssessments, setAllClassesAssessments] = useState([]);

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)")?.matches
  );

  const BATCH_SIZE = 5;

  function fetchAssessmentsInBatches(classIds) {
    const batches = [];
    for (let i = 0; i < classIds.length; i += BATCH_SIZE) {
      batches.push(classIds.slice(i, i + BATCH_SIZE));
    }

    console.log("Batches created:", batches);

    const fetchPromises = batches.map((batch, index) => {
      console.log(`Fetching batch ${index + 1} with class IDs:`, batch);
      return getClassAssessmentsForMultipleClasses(batch);
    });

    return Promise.all(fetchPromises).then((responses) => {
      console.log("Batch responses received:", responses);
      // Combine the results of all batches
      return responses.flat();
    });
  }

  // Fetch classes by school ID and then class assessments
  useEffect(() => {
    if (schoolid) {
      getClassesBySchoolId(schoolid)?.then((response) => {
        setClasses(response);
        const ids = response?.map((classItem) => classItem.id);
        setClassIds(ids);
      });
    }
  }, [schoolid]);

  useEffect(() => {
    if (classIds?.length > 0) {
      fetchAssessmentsInBatches(classIds)?.then((response) => {
        setAllClassesAssessments(response);
      });
    }
  }, [classIds]); // Ensures it only fetches when classIds is populated

  // Orientation change handler
  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window?.innerWidth < window?.innerHeight);
    };

    window?.addEventListener("resize", handleOrientationChange);

    return () => {
      window?.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window?.HubSpotConversations?.widget?.refresh();
  }, []);

  // Separate assessments into two categories while keeping the same structure
  const gradeLevel1Assessments = [];
  const gradeLevel2Assessments = [];

  allClassesAssessments?.forEach((classAssessment) => {
    // Create new data structure for gradeLevel1 and gradeLevel2
    const gradeLevel1Data = {
      classId: classAssessment?.classId,
      assessments: {},
    };
    const gradeLevel2Data = {
      classId: classAssessment?.classId,
      assessments: {},
    };

    Object.entries(classAssessment.assessments).forEach(
      ([assessmentId, assessment]) => {
        if (assessment?.grade_level1) {
          gradeLevel1Data.assessments[assessmentId] = assessment;
        }
        if (assessment?.grade_level2) {
          gradeLevel2Data.assessments[assessmentId] = assessment;
        }
      }
    );

    // Only push to gradeLevel1Assessments if it has assessments
    if (Object.keys(gradeLevel1Data.assessments).length > 0) {
      gradeLevel1Assessments.push(gradeLevel1Data);
    }

    // Only push to gradeLevel2Assessments if it has assessments
    if (Object.keys(gradeLevel2Data.assessments).length > 0) {
      gradeLevel2Assessments.push(gradeLevel2Data);
    }
  });

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <SencoResultsClassOverview
            teachers={teachers}
            gradeLevel={gradeLevel}
            name={name}
            teacherid={teacherid}
            schoolid={schoolid}
            allClassesAssessments={allClassesAssessments}
            classIds={classIds}
            gradeLevel1Assessments={gradeLevel1Assessments} // Pass grade level 1 assessments
            gradeLevel2Assessments={gradeLevel2Assessments} // Pass grade level 2 assessments
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default SencoResultDetail;
