export function groupAndAverageTasks(assessmentResultsByStudent) {
  return assessmentResultsByStudent.map((student) => {
    if (!student.tasks || student.tasks.length === 0) {
      return {
        ...student,
        tasks: [],
      };
    }

    const groupedData = {};

    student.tasks.forEach((task) => {
      // Use parentTaskid as group key; fallback to task.id for ungrouped tasks
      const groupKey = task.parentTaskid || task.name; 

      if (!groupedData[groupKey]) {
        groupedData[groupKey] = {
          name: task.parentTaskName || task.name, // Use Parent Task Name or fallback to task.name
          count: 0,
          totalScore: 0,
          totalZScore: 0,
          totalStandardScore: 0,
          totalSTenScore: 0,
          totalGroupPercentile: 0,
          totalGlobalPercentile: 0,
          totalOrder: 0,
        };
      }

      groupedData[groupKey].count++;
      groupedData[groupKey].totalScore += task.score;
      groupedData[groupKey].totalZScore += task.zScore;
      groupedData[groupKey].totalStandardScore += task.standardScore;
      groupedData[groupKey].totalSTenScore += task.sTenScore;
      groupedData[groupKey].totalGroupPercentile += task.groupPercentile;
      groupedData[groupKey].totalGlobalPercentile += task.globalPercentile;
      groupedData[groupKey].totalOrder += task.order;
    });

    const groupedTasks = Object.values(groupedData)
      .map((group) => ({
        name: group.name,
        score: group.totalScore / group.count,
        zScore: group.totalZScore / group.count,
        standardScore: group.totalStandardScore / group.count,
        sTenScore: group.totalSTenScore / group.count,
        groupPercentile: group.totalGroupPercentile / group.count,
        globalPercentile: group.totalGlobalPercentile / group.count,
        order: group.totalOrder / group.count, // Average order
      }))
      .sort((a, b) => a.order - b.order);

    return {
      ...student,
      tasks: groupedTasks,
    };
  });
}

export function groupClassResults(classesResults) {
  // Maintain the top-level class structure
  return Object.entries(classesResults).reduce((acc, [classId, classObj]) => {
    // Create new class object with same structure
    acc[classId] = {
      name: classObj.name,
      students: {}
    };

    // Process each student's results
    Object.entries(classObj.students).forEach(([studentId, student]) => {
      if (!student.results || student.results.length === 0) {
        acc[classId].students[studentId] = student; // Keep empty results as is
        return;
      }

      // Process each assessment result
      acc[classId].students[studentId] = {
        ...student,
        results: student.results.map(result => {
          const groupedTasks = {};

          // Group tasks by parent task
          result.tasks.forEach(task => {
            const groupKey = task.parentTaskid 
              ? `group-${task.parentTaskid}`
              : `task-${task.name}`;

            if (!groupedTasks[groupKey]) {
              groupedTasks[groupKey] = {
                name: task.parentTaskName || task.name,
                tasks: [],
                aggregated: {
                  score: 0,
                  zScore: 0,
                  standardScore: 0,
                  sTenScore: 0,
                  duration: 0,
                  count: 0
                }
              };
            }

            // Add task to group
            groupedTasks[groupKey].tasks.push(task);
            groupedTasks[groupKey].aggregated.score += task.score || 0;
            groupedTasks[groupKey].aggregated.zScore += task.zScore || 0;
            groupedTasks[groupKey].aggregated.standardScore += task.standardScore || 0;
            groupedTasks[groupKey].aggregated.sTenScore += task.sTenScore || 0;
            groupedTasks[groupKey].aggregated.duration += task.duration || 0;
            groupedTasks[groupKey].aggregated.count++;
          });

          // Calculate averages
          Object.values(groupedTasks).forEach(group => {
            const { aggregated, tasks } = group;
            if (aggregated.count > 0) {
              aggregated.score /= aggregated.count;
              aggregated.zScore /= aggregated.count;
              aggregated.standardScore /= aggregated.count;
              aggregated.sTenScore /= aggregated.count;
              // Duration remains as total
            }
          });

          return {
            ...result,
            groupedTasks: Object.values(groupedTasks),
            originalTasks: result.tasks // Preserve original tasks
          };
        })
      };
    });

    return acc;
  }, {});
}


export function preprocessStudentResults(individualStudentResult) {
  return individualStudentResult.map((assessment) => {
    const groupedTasks = {};

    assessment.tasks.forEach((task) => {
      let groupKey;

      // If parentTaskid is null, group based on task name; otherwise, use parentTaskid
      if (task.parentTaskid) {
        groupKey = `group-${task.parentTaskid}`;
      } else {
        groupKey = `task-${task.name}`; // Use task name for standalone tasks
      }

      if (!groupedTasks[groupKey]) {
        groupedTasks[groupKey] = {
          name: task.parentTaskid ? task.parentTaskName : task.name, // Use Parent Task Name or task.name
          aggregated: {
            score: 0,
            zScore: 0,
            standardScore: 0,
            sTenScore: 0,
            duration: 0,
            count: 0,
          },
          tasks: [], // Store individual tasks
        };
      }

      // Add task to the group
      groupedTasks[groupKey].tasks.push(task);
      groupedTasks[groupKey].aggregated.score += task.score;
      groupedTasks[groupKey].aggregated.zScore += task.zScore;
      groupedTasks[groupKey].aggregated.standardScore += task.standardScore;
      groupedTasks[groupKey].aggregated.sTenScore += task.sTenScore;
      groupedTasks[groupKey].aggregated.duration += task.duration;
      groupedTasks[groupKey].aggregated.count++;
    });

    // Calculate averages for the aggregated data
    Object.values(groupedTasks).forEach((group) => {
      const { aggregated } = group;
      aggregated.score /= aggregated.count;
      aggregated.zScore /= aggregated.count;
      aggregated.standardScore /= aggregated.count;
      aggregated.sTenScore /= aggregated.count;
      // Duration remains as the total time
    });

    return {
      ...assessment,
      groupedTasks: Object.values(groupedTasks),
    };
  });
}
