import axios from "axios";
import URL from "axios";

// const instance = axios.create({
//   ...(process.env.NODE_ENV !== "production" && {
//     baseURL: "http://localhost:3001/api/v1",
//   }),
//   ...(process.env.NODE_ENV === "production" && {
//     baseURL: "https://teacher.alpaca-assessment.com/api/v1",
//   }),
// });

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

/**
 * Add JWT token to all request headers
 */
instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");

    if (typeof token !== "undefined") {
      request.headers.authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Authenticate user with server using email and password
 *
 * @param {*} id
 * @param {*} password
 * @returns JWT token that should be used to authenticate future requests
 */
export function login(id, password) {
  return instance
    .post("/login", { email: id, password: password })
    .then((response) => {
      return response.data;
    });
}

/**
 * Retrieve unique identifier for authenticated user
 *
 * @returns UUID for currently logged in user
 */
export function whoami() {
  return instance.get("/whoAmI").then((response) => {
    return response;
  });
}

/**
 *
 * @param {*} studentid
 * @returns
 */
export function getAssessment(studentid) {
  return instance.get(`/students/${studentid}/assessments`).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {*} studentid
 * @param {*} assessmentid
 * @returns
 */
export function getTask(studentid, assessmentid) {
  return instance
    .get(`/students/${studentid}/assessments/${assessmentid}/tasks`)
    .then((response) => {
      return response.data;
    });
}

/**
 *
 * @param {*} teacherid
 * @param {*} schoolid
 * @returns
 */

export function getClasses() {
  return instance.get(`classes`).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {*} schoolid
 * @returns
 */

export function getTeachers() {
  return instance.get(`teachers/`).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {*} classid
 * @returns
 */
export function getStudentsForClass(classid) {
  return instance.get(`classes/${classid}`).then((response) => {
    return response.data;
  });
}

/**
 *
 * @returns
 */
export function getAssessmentTypes() {
  return instance.get(`assessments`).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {*} selectStudentId
 * @param {*} selectAssessmentId
 * @param {*} selectAssessmentName
 * @returns
 */
export function assignAssessment(
  selectStudentId,
  selectAssessmentId,
  selectAssessmentName
) {
  return instance
    .post(`/students/assessments`, {
      students: selectStudentId,
      assessment: {
        id: `${selectAssessmentId}`,
        name: `${selectAssessmentName}`,
      },
      isCustom: false, // Automatically set to true for custom assessments
    })
    .then((response) => {
      return response.data;
    });
}

// export function assignAssessment(selectStudentId, selectAssessmentId, selectAssessmentName) {
//   if (!Array.isArray(selectStudentId) || !selectStudentId.every(id => typeof id === 'string')) {
//     throw new Error('selectStudentId must be an array of strings.');
//   }

//   if (!selectAssessmentId || !selectAssessmentName) {
//     throw new Error('Assessment ID and name are required.');
//   }

//   const payload = {
//     students: selectStudentId.map((id) => ({ id })), // Ensure students is an array of objects
//     assessment: {
//       id: `${selectAssessmentId}`,
//       name: `${selectAssessmentName}`,
//     },
//   };

//   console.log('Payload being sent:', payload); // Debug payload

//   return instance.post(`/students/assessments`, payload)
//     .then((response) => response.data)
//     .catch((error) => {
//       console.error('Error assigning assessment:', error.response || error);
//       throw error;
//     });
// }


/**
 *
 * @param {*} assessmentInstance
 * @returns
 */
export function unassignAssessment(assessmentInstance) {
  // console.log("selectedInstnace: ", assessmentInstance);

  return instance
    .delete(`/assessment-instances/${assessmentInstance.id}`)
    .then((response) => {
      return response.data;
    });
}

/**
 *
 * @param {*} studentId
 * @param {*} data
 * @returns
 */
export function updateStudent(studentId, data) {
  return instance.patch(`/students/${studentId}`, data).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {*} classid
 * @returns
 */
export function getClassAssessments(classid) {
  return instance.get(`/classes/${classid}/assessments`).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {*} classid
 * @returns
 */
export function getClassResults(classid) {
  return instance.get(`/classes/${classid}/results`).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {string[]} classIds - Array of class IDs to fetch assessments for.
 * @returns Promise resolving to the response data.
 */
export function getMultipleClassAssessments(classIds) {
  return instance
    .post("/classes/assessments", { classIds })
    .then((response) => {
      return response.data;
    });
}


/**
 *
 * @param {*} assessmentInstanceIDs
 * @returns
 */
export function getResultsByStudent(assessmentInstanceIDs) {
  var params = new URLSearchParams();

  assessmentInstanceIDs.forEach((id) => {
    params.append("assessmentInstanceID", id);
  });

  return instance
    .get("/assessment-instances/results/students", { params: params })
    .then((response) => {
      return response.data;
    });
}

// /**
//  *
//  * @param {*} assessmentInstanceID
//  * @returns
//  */
// export function getResultsByStudent(assessmentInstanceID) {
//   return instance
//     .get("/assessment-instances/results/students", {
//       params: {
//         assessmentInstanceID: `${assessmentInstanceID}`,
//       },
//     })
//     .then((response) => {
//       return response.data;
//     });
// }

/**
 * Fetches all assessment results for a school based on its rollnumber.
 *
 * @param {string} rollnumber - The rollnumber of the school.
 * @returns {Promise} - A promise resolving to the assessment results data.
 */
export function getAllAssessmentInstances(rollnumber) {
  return instance
    .get(`/schools/rollnumber/${rollnumber}/assessment-results`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching assessment results for school:", error);
      throw error;
    });
}

/**
 * Fetch student results using a POST request.
 * @param {Array} assessmentInstanceIDs - An array of assessment instance IDs.
 * @returns Promise resolving to the response data.
 */
export function postResultsByStudent(assessmentInstanceIDs) {
  return instance
    .post("/assessment-instances/results/students", assessmentInstanceIDs)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Handle any errors here
      console.error("Error fetching student results:", error);
      throw error; // or return an error message, depending on your error handling strategy
    });
}

/**
 * Fetch student results using a POST request.
 * @param {Array} assessmentInstanceIDs - An array of assessment instance IDs.
 * @returns Promise resolving to the response data.
 */
export function getStudentResults(assessmentInstanceIDs) {
  return instance
    .post("/assessment-instances/results/students", assessmentInstanceIDs)
    .then((response) => {
      console.log("response:", response)
      return response.data;
    })
    .catch((error) => {
      // Handle any errors here
      console.error("Error fetching student results:", error);
      throw error; // or return an error message, depending on your error handling strategy
    });
}

/**
 *
 * @param {*} classid
 * @returns
 */

export function getClass(classid) {
  return instance.get(`classes/${classid}`).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {*} classid
 * @returns
 */

export function editClass(classid, nameOfClass, gradeLevel) {
  return instance
    .patch(`classes/${classid}`, {
      name: `${nameOfClass}`,
      grade_level: `${gradeLevel}`,
    })
    .then((response) => {
      return response.data;
    });
}

/**
 *
 * @param {*} classid
 * @returns
 */

export function shareClass(classid, teacherid, isSpecialCondition = false) {
  return instance
    .patch(`classes/${classid}/share`, {
      op: "add",
      teacherid: `${teacherid}`,
      isSpecialCondition,
    })
    .then((response) => {
      return response.data;
    });
}
/**
 *
 * @param {*} classid
 * @returns
 */

export function unShareClass(classid, teacherid) {
  return instance
    .patch(`classes/${classid}/share`, {
      op: "remove",
      teacherid: `${teacherid}`,
    })
    .then((response) => {
      return response.data;
    });
}

/**
 *
 * @param {*} classid
 * @returns
 */

export function addStudents(classid, name) {
  if (name?.length > 0) {
    return instance
      .post(`classes/${classid}/students`, name)
      .then((response) => {
        return response.data;
      });
  } else {
    return null;
  }
}

/**
 *
 * @param {*} classid
 * @returns
 */

export function getStudents(classid) {
  return instance.get(`classes/${classid}`).then((response) => {
    return response.data;
  });
}

export function getCountryGrades(countryid) {
  return instance.get(`/grades/${countryid}`).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {*} name
 * @param {*} grade
 * @param {*} grade1
 * @param {*} grade2
 * @param {*} trialClass
 * @param {*} specialEdClass
 * @returns
 */
export function createClass(
  name,
  grade,
  grade1,
  grade2,
  trialClass,
  specialEdClass, // Should be boolean
  classOwner,
  setClass
) {
  return instance
    .post(`/classes`, {
      name: name,
      grade_level: grade,
      grade_level1: grade1,
      grade_level2: grade2,
      trial_class: trialClass,
      specialed_class: specialEdClass, // Ensure this is a boolean
      classOwnerId: classOwner,
      set_class: setClass,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error creating class:", error.message);
      if (error.response && error.response.data) {
        console.error("Error details:", error.response.data);
      }
      throw error; // Rethrow the error to be handled in the calling code
    });
}

/**
 * Deletes the specified class
 * Current API implemenation (Sept 2022) does not enforce relational integrity constraints
 * so deleting a class will not delete any students that are associated with the class.
 * However, the link (relationship) between a student and a school will be lost since:
 *
 * School -> Teacher -> Class -> Student -> Assessment
 *
 * @param {*} classid The UUID of the class to delete.
 * @returns true if class deleted successfully (if API responds with a 204 response code)
 */
export function deleteClass(classid) {
  return instance.delete(`/classes/${classid}`).then((response) => {
    let success = false;

    if (response.status === 204) {
      success = true;
    }
    return success;
  });
}

/**
 * Deletes the specified student
 * Current API implemenation (Sept 2022) does not enforce relational integrity constraints
 * so deleting a student will not delete any assessments that are associated with the student.
 * However, the link (relationship) between an assessment and a school/country will be lost since:
 *
 * School -> Teacher -> Class -> Student -> Assessment
 *
/**
 * @param {string} studentid - The UUID of the student to delete.
 * @returns {Promise<boolean>} - true if the student was deleted successfully (if API responds with a 204 response code), false otherwise.
 */
export function deleteStudent(studentId) {
  return instance
    .delete(`/students/${studentId}`)
    .then((response) => {
      console.log(response);
      if (response.status === 204) {
        return true; // Explicitly return true on success
      } else {
        console.error("Unexpected response status:", response.status);
        return false; // Return false if the status is not what is expected
      }
    })
    .catch((error) => {
      // Improved error logging
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error deleting student:", error.message);
        console.error("Status code:", error.response.status);
        console.error("Error data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error(
          "No response received when deleting student:",
          error.request
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error(
          "Error setting up request to delete student:",
          error.message
        );
      }

      return false; // Return false on error
    });
}

/**
 *
 * @param {*} classid
 * @returns
 */
export function getQR(classid) {
  return instance.get(`classes/${classid}/qr`).then((response) => {
    return response.data;
  });
}

/**
 *
 * @param {*} studentid
 * @returns
 */
export function getIndividualStudentResults(studentid) {
  return instance
    .get(`students/${studentid}/assessments/results`)
    .then((response) => {
      // console.log(response.data)
      return response.data;
    });
}

/**
 * Post student answers for a specific task to the server
 *
 * @param {*} studentid
 * @param {*} assessmentid
 * @param {*} taskid
 * @param {*} results
 */
export function saveTaskAnswers(studentid, assessmentid, taskid, results) {
  instance
    .post(
      `/students/${studentid}/assessments/${assessmentid}/tasks/${taskid}/answers`,
      results
    )
    .then((response) => { });
}

/**
 * Post email to request password reset
 *
 * @param {*} email
 *
 */
export function requestPasswordReset(email) {
  instance.post(`/user/password/request`, { email });
}

/**
 * Post new password with token
 * @param {string} password The new password to set.
 * @param {string} token The reset token linked to the user's password reset request.
 */
export function updatePassword(password, token) {
  console.log(password);
  console.log(token);
  return instance
    .post(`/user/password/resetPassword/${token}`, { password })
    .then((response) => {
      console.log("Password update successful:", response.data);
      return { success: true, message: "Password updated successfully." };
    })
    .catch((error) => {
      console.error("Error updating password:", error.response);
      return {
        success: false,
        message: "Failed to update password. Please try again.",
      };
    });
}

/**
 * Fetches all assessment results for a student based on their student ID.
 *
 * @param {string} studentid - The ID of the student.
 * @returns {Promise} - A promise resolving to the assessment results data.
 */
export function getAllStudentResults(studentid) {
  return instance
    .get(`/students/${studentid}/assessments/results`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching assessment results for student:", error);
      throw error;
    });
}

// export function updatePassword(password, token) {
//   return instance.post(`/user/password/resetPassword/${token}`, { password })
//     .then(response => {
//       console.log('Password update successful:', response.data);
//       return { success: true, message: 'Password updated successfully.' };
//     })
//     .catch(error => {
//       console.error('Error updating password:', error.response);
//       if (error.response) {
//         // Handle different cases based on the status code
//         const status = error.response.status;
//         if (status === 404) {
//           return { success: false, message: 'Token not valid or expired.' };
//         } else if (status === 422) {
//           return { success: false, message: 'Validation failed. Check the provided data.' };
//         }
//       }
//       return { success: false, message: 'Failed to update password. Please try again.' };
//     });
// }

/**
 * Update teacher's profile details
 *
 * @param {string} teacherId - The ID of the teacher to update.
 * @param {object} data - The data to update (name, additionalRoles, mobileNumber).
 * @returns The updated teacher data.
 */

export function updateTeacherProfile(schoolId, teacherId, data) {
  return instance
    .patch(`/schools/${schoolId}/teachers/${teacherId}`, data)
    .then((response) => {
      return response.data;
    });
}

/**
 * Post new user data
 *
 * @param {*} user
 *
 */
export function signupTeacher(user) {
  const { email, password, school } = user;

  // console.log(user)

  return new Promise((resolve, reject) => {
    instance
      .post(`/signup`, {
        email,
        password,
        roles: ["teacher"],
        stakeholderType: "Teacher",
      })
      .then((userData) => {
        login(email, password)
          .then((loginData) => {
            localStorage.setItem("token", loginData.token);
            instance
              .post(`/schools/${school}/teachers`, {
                name: user.name,
                userId: userData.data.id,
                classroomTeacher: true,
                additionalRoles: user.roles,
                mobileNumber: user.mobileNumber,
                language: "en",
              })
              .then((res) => {
                resolve(true);
              })
              .catch((error) => {
                // console.log("Error creating new teacher", error.response);
                reject(false);
              });
          })
          .catch((error) => {
            // console.log("Error logging in new user", error.response);
            reject(false);
          });
      })
      .catch((error) => {
        // console.log("Error creating new user", error.response);
        reject(false);
      });
  });
}

/**
 * Get all schools
 *
 */
export function getSchools() {
  return instance.get("/schools");
}

/**
 * Get all schools
 *
 */
export function getIndividualSchool(schoolid) {
  return instance.get(`/schools/${schoolid}`).then((response) => {
    return response.data;
  });
}

/**
 * Get all rolescd
 *
 */
export function getRoles() {
  return instance.get("/roles");
}

export function getPendingTeacher(pendingteacherid) {
  return instance
    .get(`/pending-teachers/${pendingteacherid}`)
    .then((response) => {
      //  console.log(response.data)
      return response.data;
    });
}

export function deletePendingTeacher(pendingteacherid) {
  return instance
    .delete(`/pending-teachers/${pendingteacherid}`)
    .then((response) => {
      // console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error deleting pending teacher:", error);
      throw error;
    });
}

/**
 *
 * @param {*} classid
 * @returns
 */
export function getPendingTeacherBySchoolId(schoolid) {
  return instance
    .get(`/pending-teachers/school/${schoolid}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching pending teachers:", error);
      // Depending on your setup, you might want to throw an error or return a default value
      throw error;
    });
}

/**
 * Function to add additional colleagues as pending teachers.
 * @param {string} schoolId - The ID of the school the colleagues are associated with.
 * @param {string} rollnumber - The roll number to be used for each colleague.
 * @param {Array} colleagues - Array of objects, each containing the name, email, and optional sharedClassId of a colleague.
 * @param {string} teacherName - The name of the teacher adding the colleagues.
 * @returns {Promise} A promise that resolves to the response of the API call.
 */
export function addAdditionalColleagues(
  schoolId,
  rollnumber,
  colleagues,
  teacherName,
  countryId
) {
  console.log(schoolId);
  console.log(rollnumber); // Ensure this logs the expected value
  console.log(colleagues);
  console.log(teacherName);
  console.log(countryId);

  if (!colleagues || colleagues.length === 0) {
    console.warn("No colleagues provided to add.");
    return Promise.reject("No colleagues provided to add.");
  }

  // Use the rollnumber parameter for each user, including the optional sharedClassId
  const users = colleagues.map(
    ({ firstName, lastName, email, sharedClassId }) => ({
      firstName,
      lastName,
      email,
      rollnumber, // This now correctly uses the rollnumber passed to the function
      roles: "Teacher", // Assuming a default role for simplicity.
      sharedClassId, // Include the optional sharedClassId
    })
  );

  const payload = {
    users,
    schoolId,
    teacherName,
    countryId,
  };

  console.log(users.sharedClassId, "PAYLOAD");

  return instance
    .post("/pending-teachers", payload)
    .then((response) => {
      console.log(response);

      return response.data;
    })
    .catch((error) => {
      console.error("Error adding additional colleagues:", error);
      throw error;
    });
}

/**
 * Check if the colleague already exists in the pending teachers' table.
 *
 * @param {string} email - The email of the colleague to check.
 * @returns {Promise<boolean>} - True if the colleague exists, false otherwise.
 */
export function checkColleagueExists(email) {
  return instance
    .get("/pending-teachers/check", { params: { email } })
    .then((response) => response.data.exists)
    .catch((error) => {
      console.error("Error checking if colleague exists:", error);
      throw error;
    });
}

/**
 * Function to submit multiple referrals.
 * @param {string} referrerId - The ID of the teacher making the referrals.
 * @param {Array} referrals - Array of objects, each containing the email (and possibly name) of the referred teacher.
 * @returns {Promise} A promise that resolves to the response of the API call.
 */
export function submitMultipleReferrals(teacherId, referrals, teacherADetails) {
  if (!referrals || referrals.length === 0) {
    console.warn("No referrals provided.");
    return Promise.reject("No referrals provided.");
  }

  const referralEntries = referrals.map(({ email, firstName, lastName }) => ({
    teacherId,
    referredEmail: email,
    firstName,
    lastName,
    referralCode: "DefaultCode",
    status: "Pending",
    inPendingTeacherTable: false,
    uniqueUrlSent: false,
    createDate: new Date().toISOString(),
    // Including TeacherA's details
    referrerName: teacherADetails.fullName,
    referrerEmail: teacherADetails.email,
  }));

  console.log(referralEntries);

  return instance
    .post("/referrals/batch", referralEntries)
    .then((response) => {
      console.log("Submission successful", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error submitting multiple referrals:", error);
      throw error;
    });
}

/**
 * Fetch report options based on the type of report.
 * @param {boolean} isSenco - If true, fetch options for SENCO, false for JISI.
 * @returns {Promise}
 */
export function getReportOptions(isSenco) {
  const reportTypeParam = isSenco ? "sencoreport=true" : "jisireport=true";
  return instance
    .get(`/report-options?${reportTypeParam}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching report options:", error);
      throw error;
    });
}

/**
 * Upserts JISI report based on the provided data.
 * @param {Object} reportData - The JSON data for the JISI report.
 * @returns {Promise}
 */
export function upsertJiSiReport(reportData) {
  return instance
    .post("/ji-si-reports/upsert", reportData)
    .then((response) => {
      console.log("Upsert JISI Report Success:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error upserting JISI Report:", error);
      throw error;
    });
}

/**
 * Fetches a JiSiReport based on student ID.
 * @param {string} studentId - The ID of the student to fetch the report for.
 * @returns {Promise}
 */
export function getJiSiReportByStudentId(studentId) {
  return instance
    .get(`/ji-si-reports/by-student/${studentId}`)
    .then((response) => {
      // console.log("Fetched JiSi Report:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching JiSi Report by student ID:", error);
      throw error;
    });
}

/**
 * Fetches a JiSiReport based on class ID.
 * @param {string} classId - The ID of the class to fetch the report for.
 * @returns {Promise}
 */
export function getJiSiReportByClassId(classId) {
  return instance
    .get(`/ji-si-reports/by-class/${classId}`)
    .then((response) => {
      // Logging can be uncommented for debugging purposes or removed after testing
      // console.log("Fetched JiSi Report:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching JiSi Report by class ID:", error);
      // Propagating error up to the caller allows for more flexible error handling
      throw error;
    });
}

/**
 * Upserts SENCO report based on the provided data.
 * @param {Object} reportData - The JSON data for the SENCO report.
 * @returns {Promise}
 */
export function upsertSencoReport(reportData) {
  return instance
    .post("/senco-reports/upsert", reportData)
    .then((response) => {
      console.log("Upsert SENCO Report Success:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error upserting SENCO Report:", error);
      throw error;
    });
}

/**
 * Fetches a SencoReport based on student ID.
 * @param {string} studentId - The ID of the student to fetch the report for.
 * @returns {Promise}
 */
export function getSencoReportByStudentId(studentId) {
  return instance
    .get(`/senco-reports/by-student/${studentId}`)
    .then((response) => {
      console.log("Fetched SENCO Report:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching SENCO Report by student ID:", error);
      throw error;
    });
}

/**
 * Fetches a SencoReport based on class ID.
 * @param {string} classId - The ID of the class to fetch the report for.
 * @returns {Promise}
 */
export function getSencoReportByClassId(classId) {
  return instance
    .get(`/senco-reports/by-class/${classId}`)
    .then((response) => {
      // Logging can be uncommented for debugging purposes or removed after testing
      // console.log("Fetched Senco Report:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching Senco Report by class ID:", error);
      // Propagating error up to the caller allows for more flexible error handling
      throw error;
    });
}

/**
 * Resend an invitation to a teacher using a POST request.
 * @param {string} teacherId - The ID of the teacher to whom the invite should be resent.
 * @returns {Promise} - A promise resolving to the response data.
 */
export function resendTeacherInvite(teacherId) {
  return instance
    .post(`/pending-teachers/remind/${teacherId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error resending invite:", error);
      throw error;
    });
}

/**
 * Add Alpaca Lead role to a teacher
 */
export function addAlpacaLeadRole(schoolId, teacherId) {
  return instance
    .patch(`/schools/${schoolId}/teachers/${teacherId}/add-alpaca-lead`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error adding Alpaca Lead role:", error);
      throw error;
    });
}

/**
 * Remove Alpaca Lead role from a teacher
 */
export function removeAlpacaLeadRole(schoolId, teacherId) {
  return instance
    .patch(`/schools/${schoolId}/teachers/${teacherId}/remove-alpaca-lead`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error removing Alpaca Lead role:", error);
      throw error;
    });
}

/**
 * Function to update the class ID of a student
 * @param {string} studentId - The ID of the student to update
 * @param {string} newClassId - The new class ID to assign to the student
 * @returns {Promise} - A promise resolving to the response data
 */
export function updateStudentClassId(studentId, newClassId) {
  return instance.patch(`/students/${studentId}/class-id`, {
    classId: newClassId,
  });
}

/**
 * Fetches AnswerDeletionReason options.
 * @returns {Promise}
 */
export function getAnswerDeletionReasonOptions() {
  return instance
    .get("answer-deletion-reasons")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching AnswerDeletionReason options:", error);
      throw error;
    });
}

/**
 * Fetches ChangeClassReason options.
 * @returns {Promise}
 */
export function getChangeClassReasonOptions() {
  return instance
    .get("/change-class-reasons")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching ChangeClassReason options:", error);
      throw error;
    });
}

/**
 * Fetches Change Alpaca Lead options.
 * @returns {Promise}
 */
export function getAlpacaLeadChangeReasonOptions() {
  return instance
    .get("/alpaca-lead-change-reasons")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching AlpacaLeadChange options:", error);
      throw error;
    });
}
/**
 * Deletes the specified student
 * Current API implemenation (Sept 2022) does not enforce relational integrity constraints
 * so deleting a student will not delete any assessments that are associated with the student.
 * However, the link (relationship) between an assessment and a school/country will be lost since:
 *
 * School -> Teacher -> Class -> Student -> Assessment
 *
/**
 * @param {string} answerId - The UUID of the answer to delete.
 * @returns {Promise<boolean>} - true if the answer was deleted successfully (if API responds with a 204 response code), false otherwise.
 */
export function deleteAnswer(answerId) {
  return instance
    .delete(`/answers/${answerId}`)
    .then((response) => {
      console.log(response);
      if (response.status === 204) {
        return true; // Explicitly return true on success
      } else {
        console.error("Unexpected response status:", response.status);
        return false; // Return false if the status is not what is expected
      }
    })
    .catch((error) => {
      // Improved error logging
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error deleting student:", error.message);
        console.error("Status code:", error.response.status);
        console.error("Error data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error(
          "No response received when deleting answer:",
          error.request
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error(
          "Error setting up request to delete answer:",
          error.message
        );
      }

      return false; // Return false on error
    });
}

// /**
//  * Updates the answer deletion reason table.
//  * @param {string} answerId - The UUID of the answer.
//  * @param {string} reasonId - The ID of the deletion reason.
//  * @param {string} deletionReason - The textual reason for deletion.
//  * @returns {Promise<boolean>} - true if the deletion reason was updated successfully, false otherwise.
//  */
// export function updateAnswerDeletionReason(answerId, reasonId, deletionReason, teacherId) {
//   return instance
//     .post(`/answer-deletion-reasons`, {
//       answerid: answerId,
//       answerdeletionreasonsid: reasonId,
//       deletionreason: deletionReason,
//       teacherid: teacherId,

//     })
//     .then((response) => {
//       console.log(response);
//       return response.status === 200;
//     })
//     .catch((error) => {
//       console.error("Error updating answer deletion reason:", error);
//       return false;
//     });
// }

/**
 * Creates an AnswerDeleted record based on the provided data.
 * @param {Object} answerDeletedData - The JSON data for the AnswerDeleted record.
 * @returns {Promise}
 */
export function updateAnswerDeletionReason(answerDeletedData) {
  return instance
    .post("/answers-deleted", answerDeletedData)
    .then((response) => {
      console.log("Answer Deleted Record Creation Success:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error creating Answer Deleted Record:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request data:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
      throw error;
    });
}

/**
 * Creates an AnswerDeleted record based on the provided data.
 * @param {Object} classChangedData - The JSON data for the AnswerDeleted record.
 * @returns {Promise}
 */
export function updateClassChangeReason(classChangedData) {
  return instance
    .post("/class-change-histories", classChangedData)
    .then((response) => {
      console.log("Class Changed Record Creation Success:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error creating Class Changed Record:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request data:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
      throw error;
    });
}

/**
 * Creates an AnswerDeleted record based on the provided data.
 * @param {Object} alpacaLeadChangeData - The JSON data for the AnswerDeleted record.
 * @returns {Promise}
 */
export function updateAlpacaLeadChangeReason(alpacaLeadChangeData) {
  return instance
    .post("/alpaca-lead-histories", alpacaLeadChangeData)
    .then((response) => {
      console.log(
        "Alpaca Lead Changed Record Creation Success:",
        response.data
      );
      return response.data;
    })
    .catch((error) => {
      console.error("Error creating Alpaca Lead Changed Record:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request data:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
      throw error;
    });
}

/**
 * Creates a SpecialEdStudent based on the provided data.
 * @param {Object} studentData - The JSON data for the SpecialEdStudent.
 * @returns {Promise}
 */
export function createSpecialEdStudent(studentData) {
  return instance
    .post("/special-ed-students", studentData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error creating SpecialEdStudent:", error);
      throw error;
    });
}

/**
 *
 * @param {*} schoolid
 * @returns
 */
export function getAllowedProperties(schoolid) {
  return instance.get(`/schools/${schoolid}/config`).then((response) => {
    return response.data;
  });
}

/**
 * Fetches all ClassOwnerChangeReason entries.
 *
 * @returns {Promise<Array>} - A promise that resolves to an array of ClassOwnerChangeReason data.
 */
export function getClassOwnerChangeReasons() {
  return instance
    .get("/class-owner-change-reasons")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching ClassOwnerChangeReasons:", error);
      throw error;
    });
}

/**
 * Updates the classOwnerId of a class by ID.
 *
 * @param {string} classId - The ID of the class to update.
 * @param {string} classOwnerId - The new classOwnerId value.
 * @returns {Promise<object>} - A promise that resolves to the updated class data.
 */
export function updateClassOwnerId(classId, classOwnerId) {
  // Prepare the data object to send in the request body
  const data = {
    classOwnerId: classOwnerId,
  };

  return instance
    .patch(`/classes/${classId}`, data)
    .then((response) => {
      // Return the updated class data from the response
      return response.data;
    })
    .catch((error) => {
      // Handle errors if the request fails
      console.error("Error updating classOwnerId:", error);
      throw error; // Re-throw the error to be handled by the caller
    });
}

/**
 * Creates a new ClassOwnershipHistory entry.
 *
 * @param {Object} classOwnershipHistoryData - The data for the new ClassOwnershipHistory entry.
 * @param {string} classOwnershipHistoryData.teacherId - The ID of the teacher associated with the ownership change.
 * @param {string} classOwnershipHistoryData.oldClassOwner - The ID of the previous class owner.
 * @param {string} classOwnershipHistoryData.newClassOwner - The ID of the new class owner.
 * @param {string} classOwnershipHistoryData.reasonForChangeId - The ID of the reason for the ownership change.
 * @param {string} [classOwnershipHistoryData.classownerchangereason] - A string describing the reason for the change.
 * @param {Date} [classOwnershipHistoryData.dateChanged] - The date of the ownership change. Defaults to the current date.
 * @returns {Promise<Object>} - A promise that resolves to the newly created ClassOwnershipHistory data.
 */
export function createClassOwnershipHistory(classOwnershipHistoryData) {
  return instance
    .post("/class-ownership-histories", classOwnershipHistoryData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error creating ClassOwnershipHistory:", error);
      throw error;
    });
}

/**
 * Updates the grade levels of a class.
 *
 * This function sets `grade_level1` to `false` and `grade_level2` to `true` for the specified class.
 *
 * @param {string} classId - The ID of the class to update.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 */
export function updateGradeLevels(classId) {
  return axios
    .patch(`/classes/${classId}/update-grade-levels`)
    .then((response) => {
      console.log("Grade levels updated successfully");
    })
    .catch((error) => {
      console.error("Error updating grade levels:", error);
      throw error;
    });
}

/**
 * Creates a LastLogin based on the provided data.
 * @param {Object} studentData - The JSON data for the LastLogin.
 * @returns {Promise}
 */
export function postLastLogin(teacherid) {
  return instance
    .post("/lastloggedin", { teacherid })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error creating Logging Last Login:", error);
      throw error;
    });
}

/**
 * Fetches the most recent LastLogin entry for the given teacherid.
 * @param {string} teacherid - The teacher's ID.
 * @returns {Promise}
 */
export function getLastLogin(teacherid) {
  return instance
    .get(`/lastloggedin/${teacherid}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching last login:", error);
      throw error;
    });
}

export function getAllStudentsResultsPerAssessment(
  studentIds,
  assessmentInstanceIds
) {
  // Log the data being passed to the function
  console.log("Preparing to send request with the following data:");
  console.log("Student IDs:", studentIds);
  console.log("Assessment Instance IDs:", assessmentInstanceIds);

  return instance
    .post("/students/assessments/results", {
      studentIds: studentIds,
      assessmentInstanceIds: assessmentInstanceIds,
    })
    .then((response) => {
      // Log the response data
      console.log("Received response:", response.data);
      return response.data;
    })
    .catch((error) => {
      // Handle any errors here
      console.error("Error fetching student results:", error);
      throw error; // or return an error message, depending on your error handling strategy
    });
}


/**
 * Creates a new trialist entry.
 * @param {Object} trialistData - The data for the new trialist.
 * @returns {Promise}
 */
export function createTrialist(trialistData) {
  return instance
    .post('/trialists', trialistData)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error creating trialist:", error);
      throw error;
    });
}

/**
 * Creates a trialist entry for a specific school and trial.
 * This function sends a POST request to the backend API, creating a trialist.
 *
 * @param {string} schoolid - The unique identifier for the school.
 * @param {string} trialid - The unique identifier for the trial.
 * @returns {Promise<Object>} A promise that resolves with the data of the newly created trialist.
 */
export function createTrialistBySchoolid(schoolid, trialid) {
  return instance
    .post(`/trialists/${schoolid}/${trialid}`)
    .then(response => {
      // Successfully created the trialist
      return response.data;
    })
    .catch(error => {
      // Handle different types of errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Backend returned status code:', error.response.status);
        console.error('Error data:', error.response.data);
        throw new Error(`Backend error: ${error.response.data.message || 'Unknown error'}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
        throw new Error('No response from backend');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
        throw new Error(error.message);
      }
    });
}


/**
 * Fetches trialist entries for a specific school.
 * @param {string} schoolid - The ID of the school.
 * @returns {Promise}
 */
export function getTrialistsBySchool(schoolid) {
  return instance
    .get(`/trialists/school/${schoolid}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching trialists by school ID:", error);
      throw error;
    });
}

/**
 * Updates the start date for a trialist and sets started to true.
 * @param {string} schoolid - The school ID.
 * @param {string} start_date - The start date in ISO format.
 * @returns {Promise}
 */
export function updateTrialistStartDate(schoolid, start_date) {
  return instance
    .patch(`/trialists/school/${schoolid}/start`, { start_date })
    .catch((error) => {
      console.error("Error updating trialist start date:", error);
      throw error;
    });
}

/**
 * Updates the end date for a trialist.
 * @param {string} schoolid - The school ID.
 * @param {string} end_date - The end date in ISO format.
 * @returns {Promise}
 */
export function updateTrialistEndDate(schoolid, end_date) {
  return instance
    .patch(`/trialists/school/${schoolid}/end`, { end_date })
    .catch((error) => {
      console.error("Error updating trialist end date:", error);
      throw error;
    });
}

/**
 * Applies an extension to a trialist, setting extension_applied to true and updating the end date.
 * @param {string} schoolid - The school ID.
 * @returns {Promise}
 */
export function applyTrialistExtension(schoolid) {
  return instance
    .patch(`/trialists/school/${schoolid}/extension`, { extension_applied: true })
    .catch((error) => {
      console.error("Error applying trialist extension:", error);
      throw error;
    });
}

/**
 * Marks a trialist as completed.
 * @param {string} schoolid - The school ID.
 * @param {boolean} completed - Completion status.
 * @returns {Promise}
 */
export function markTrialistCompleted(schoolid, completed = true) {
  return instance
    .patch(`/trialists/school/${schoolid}/completed`, { completed })
    .catch((error) => {
      console.error("Error marking trialist as completed:", error);
      throw error;
    });
}

/**
 * Marks a trialist as expired.
 * @param {string} schoolid - The school ID.
 * @param {boolean} expired - Expired status.
 * @returns {Promise}
 */
export function markTrialistExpired(schoolid, expired = true) {
  return instance
    .patch(`/trialists/school/${schoolid}/expired`, { expired })
    .catch((error) => {
      console.error("Error marking trialist as expired:", error);
      throw error;
    });
}

/**
 * Marks a trialist as purchased.
 * @param {string} schoolid - The school ID.
 * @param {boolean} purchased - Purchased status.
 * @returns {Promise}
 */
export function markTrialistPurchased(schoolid, purchased = true) {
  return instance
    .patch(`/trialists/school/${schoolid}/purchased`, { purchased })
    .catch((error) => {
      console.error("Error marking trialist as purchased:", error);
      throw error;
    });
}

/**
 * Fetches a trialist entry by ID.
 * @param {string} id - The ID of the trialist.
 * @returns {Promise}
 */
export function getTrialistById(id) {
  return instance
    .get(`/trialists/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching trialist by ID:", error);
      throw error;
    });
}

/**
 * Updates a trialist entry by ID.
 * @param {string} id - The ID of the trialist.
 * @param {Object} updateData - The data to update.
 * @returns {Promise}
 */
export function updateTrialistById(id, updateData) {
  return instance
    .patch(`/trialists/${id}`, updateData)
    .catch((error) => {
      console.error("Error updating trialist by ID:", error);
      throw error;
    });
}


/**
 * Updates the user activity for a specific teacher.
 * @param {string} teacherid - The teacher's ID.
 * @param {string} activity - The activity type to update (e.g., "class_created", "students_added").
 * @returns {Promise}
 */
export function updateUserActivity(teacherid, activity) {
  return instance
    .post('/user-activity', { teacherid, activity })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error updating user activity:", error);
      throw error;
    });
}

export function sendPurchaseEmail(email, teacherName) {
  return instance
    .post('/send-purchase-email', { email, teacherName }) // POST request with data in the body
    .then((response) => {
      console.log('Email sent successfully:', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error('Error sending purchase email:', error);
      throw error;
    });
}


// SENCO View Calls

/**
 * Fetch assessments for multiple classes
 * @param {string[]} classIds - Array of class IDs
 * @returns {Promise<any[]>} - Promise resolving to the assessment data for each class
 */
export function getClassAssessmentsForMultipleClasses(classIds) {
  return instance
    .post("/classes/assessments", { classIds })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching class assessments:", error);
      throw error;
    });
}

/**
 * Fetch all classes for a school
 * @param {string} schoolId - The ID of the school
 * @returns {Promise<any[]>} - Promise resolving to the list of classes for the school
 */
export function getClassesBySchoolId(schoolId) {
  return instance
    .get(`/schools/${schoolId}/classes`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching classes for school:", error);
      throw error;
    });
}

export function getClassesAssessmentResults(classIds, assessmentId) {
  return instance
    .post("/classes/assessments/results", {
      classIds: classIds,
      assessmentId: assessmentId,
    })
    .then((response) => {
      console.log(response)
      return response.data;
    })
    .catch((error) => {
      // Handle any errors here
      console.error("Error fetching class assessment results:", error);
      throw error; // or return an error message, depending on your error handling strategy
    });
}

/**
 * Fetch all classes for a school
 * @param {string} schoolId - The ID of the school
 * @returns {Promise<any[]>} - Promise resolving to the list of classes for the school
 */
export function getClassesByAssessmentId(assessmentId, schoolId) {
  return instance
    .get(`/schools/${schoolId}/classes/by-assessment/${assessmentId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching classes for assessment:", error);
      throw error;
    });
}

/**
 *
 * @param {*} studentId
 * @param {*} classId
 * @returns
 */
export function addAdditionalClass(studentId, classId) {
  return instance
    .post(`students/${studentId}/additional-classes`, { classId })
    .then((response) => {
      return response.data; // Return the created StudentClass object
    })
    .catch((error) => {
      console.error("Error adding additional class:", error);
      throw error; // Propagate the error for further handling
    });
}

/**
 * Posts a new ScoreHeatMap record based on the provided data.
 * @param {Object} scoreHeatMapData - The JSON data for the ScoreHeatMap.
 * @returns {Promise}
 */
export function createScoreHeatMap(scoreHeatMapData) {
  return instance
    .post("/score-heat-maps", scoreHeatMapData)
    .then((response) => {
      console.log("Create ScoreHeatMap Success:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error creating ScoreHeatMap:", error);
      throw error;
    });
}

/**
 * Deletes an additional class entry for a student.
 * @param {string} studentId - The ID of the student.
 * @param {string} classId - The ID of the additional class to delete.
 * @returns {Promise<void>} - A promise that resolves when the deletion is successful.
 */
export function deleteAdditionalClass(studentId, classId) {
  return instance
    .delete(`students/${studentId}/additional-classes/${classId}`)
    .then((response) => {
      return response.data; // You may not need to return data, but can log it if necessary
    })
    .catch((error) => {
      console.error("Error deleting additional class:", error);
      throw error; // Rethrow error to handle it in the calling code
    });
}

export function getAssessmentInstancesByStudentIds(studentIds) {
  return instance
    .post("assessment-instances/students", { studentIds }) // Send studentIds as part of the request body
    .then((response) => {
      return response.data; // Return the array of AssessmentInstance objects
    })
    .catch((error) => {
      console.error("Error fetching assessment instances:", error);
      throw error; // Propagate the error for further handling
    });
}

// Function to create a new RTI record
export const createRti = async (rtiData) => {
  console.log("Sending RTI data:", rtiData); // Log the data being sent

  return instance
    .post("/rti", rtiData)
    .then((response) => {
      console.log("RTI creation successful:", response); // Log the full response
      console.log("RTI data received:", response.data); // Log only the response data
      return response.data;
    })
    .catch((error) => {
      console.error("Error creating RTI:", error); // Log error details
      console.log("rtiData", rtiData)
      if (error.response) {
        console.error("Error response data:", error.response.data); // Log error response data
        console.error("Error response status:", error.response.status); // Log error status code
        console.error("Error response headers:", error.response.headers); // Log error headers
      }
      throw error;
    });
};


export function getRtiRecords({ teacherid, studentid, taskid }) {
  // Build query parameters dynamically
  const params = new URLSearchParams();
  if (teacherid) params.append("teacherid", teacherid);
  if (studentid) params.append("studentid", studentid);
  if (taskid) params.append("taskid", taskid);

  // Make GET request with query parameters
  return instance
    .get(`/rti?${params.toString()}`)
    .then((response) => {
      console.log("Fetched RTI records:", response.data); // Log fetched data
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching RTI records:", error);
      throw error;
    });
}


export function assignCustomAssessment(
  selectStudentId,
  selectAssessmentId,
  selectAssessmentName
) {
  return instance
    .post(`/students/assessments`, {
      students: selectStudentId,
      assessment: {
        id: `${selectAssessmentId}`,
        name: `${selectAssessmentName}`,
      },
      isCustom: true, // Automatically set to true for custom assessments
    })
    .then((response) => {
      return response.data;
    });
}
/**
 * Retrieves tasks based on the specified grade level.
 *
 * @param {number} gradeLevel - The grade level to filter tasks by (1 or 2).
 * @returns {Promise} - Resolves to an array of tasks matching the grade level.
 */
export function getTasksByGradeLevel(gradeLevel) {
  return instance
    .get(`/tasks/grade-level/${gradeLevel}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error retrieving tasks for grade level:", gradeLevel, error);
      throw error;
    });
}
/**
 * Creates a custom assessment
 *
 * @param {string} teacherId - The ID of the teacher creating the assessment
 * @param {string} name - The name of the assessment
 * @param {boolean} gradeLevel1 - Whether the assessment is for grade level 1
 * @param {boolean} gradeLevel2 - Whether the assessment is for grade level 2
 * @returns {Promise} - Resolves to the created custom assessment object
 */
export function createCustomAssessment(teacherId, name, gradeLevel1, gradeLevel2) {
  return instance
    .post(`/custom-assessments`, {
      teacherid: teacherId,
      name: name,
      grade_level1: gradeLevel1,
      grade_level2: gradeLevel2,
    })
    .then((response) => {
      return response.data;
    });
}
/**
 * Retrieves custom assessments for a specific teacher
 *
 * @param {string} teacherId - The ID of the teacher
 * @returns {Promise} - Resolves to an array of custom assessments for the teacher
 */
export function getCustomAssessments(teacherId) {
  return instance
    .get(`/custom-assessments/${teacherId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error retrieving custom assessments for teacher:", teacherId, error);
      throw error;
    });
}
/**
 * Adds steps to a custom assessment
 *
 * @param {string} customAssessmentId - The ID of the custom assessment
 * @param {Array} steps - Array of step objects, each containing a taskid and order
 * @returns {Promise} - Resolves to an array of created custom assessment steps
 */
export function createCustomAssessmentSteps(customAssessmentId, steps) {
  return instance
    .post(`/custom-assessment-steps/${customAssessmentId}`, steps)
    .then((response) => {
      return response.data;
    });
}
/**
 * Retrieves steps for a specific custom assessment
 *
 * @param {string} customAssessmentId - The ID of the custom assessment
 * @returns {Promise} - Resolves to an array of steps for the custom assessment
 */
export function getCustomAssessmentSteps(customAssessmentId) {
  return instance
    .get(`/custom-assessment-steps/${customAssessmentId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error retrieving steps for custom assessment:", customAssessmentId, error);
      throw error;
    });
}


export function getAllTaskRecommendations(...taskids) {
  let response = [];

  // taskids.forEach( id => {
  //   response.push( getRecommendations( id ) );
  // })

  const canned = [
    {
      id: 1,
      name: "Rhyming",
      // description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. At expedita fugit repellendus impedit dolorum pariatur molestiae perspiciatis velit amet odio quas quidem, debitis distinctio adipisci beatae et culpa ipsa quam.Lorem ipsum dolor sit, amet consectetur adipisicing elit. At expedita fugit repellendus impedit dolorum pariatur molestiae perspiciatis velit amet odio quas quidem, debitis distinctio adipisci beatae et culpa ipsa quam.Lorem ipsum dolor sit, amet consectetur adipisicing elit. At expedita fugit repellendus impedit dolorum pariatur molestiae perspiciatis velit amet odio quas quidem, debitis distinctio adipisci beatae et culpa ipsa quam.Lorem ipsum dolor sit, amet consectetur adipisicing elit. At expedita fugit repellendus impedit dolorum pariatur molestiae perspiciatis velit amet odio quas quidem, debitis distinctio adipisci beatae et culpa ipsa quam.',
      // video: 'https://file-examples.com/storage/fe4658769b6331540b05587/2017/04/file_example_MP4_480_1_5MG.mp4',
      files: {
        all: "./docs/Alpaca_Rhyming_Recommendations.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
    {
      id: 2,
      name: "Initial Phoneme",
      // description: 'Description',
      // video: 'https://www.youtube.com/watch?v=K4TOrB7at0Y',
      files: {
        all: "./docs/Alpaca_Initial_Phoneme_Isolation_Recommendations.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
    {
      id: 3,
      name: "Phoneme Blending",
      // description: 'Description',
      // video: 'https://www.youtube.com/watch?v=K4TOrB7at0Y',
      files: {
        all: "./docs/Alpaca_Phoneme_Blending_Recommendations.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
    {
      id: 4,
      name: "Letter Name Knowledge",
      // description: 'Description',
      // video: 'https://www.youtube.com/watch?v=K4TOrB7at0Y',
      files: {
        all: "./docs/Alpaca_Letter_Name_Knowledge_Recommendations.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
    {
      id: 5,
      name: "Phoneme Deletion",
      // description: 'Description',
      // video: 'https://www.youtube.com/watch?v=K4TOrB7at0Y',
      files: {
        all: "./docs/Alpaca_Phoneme_Deletion_Recommendations.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
    {
      id: 6,
      name: "Letter Sound Knowledge",
      // description: 'Description',
      // video: 'https://www.youtube.com/watch?v=K4TOrB7at0Y',
      files: {
        all: "./docs/Alpaca_Letter_Sound_Knowledge_Recommendations.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
    {
      id: 7,
      name: "Word Recognition",
      // description: 'Description',
      // video: 'https://www.youtube.com/watch?v=K4TOrB7at0Y',
      files: {
        all: "./docs/Alpaca_Word_Recognition_Recommendations.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
    {
      id: 8,
      name: "Letter Sound II",
      // description: 'Description',
      // video: 'https://www.youtube.com/watch?v=K4TOrB7at0Y',
      files: {
        all: "./docs/LetterSound-Midpoint.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
    {
      id: 9,
      name: "Phonological Awareness",
      // description: 'Description',
      // video: 'https://www.youtube.com/watch?v=K4TOrB7at0Y',
      files: {
        all: "./docs/Phonological Awareness-Midpoint.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
    {
      id: 10,
      name: "Spelling",
      // description: 'Description',
      // video: 'https://www.youtube.com/watch?v=K4TOrB7at0Y',
      files: {
        all: "./docs/Spelling-Midpoint.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
    {
      id: 11,
      name: "Word Reading",
      // description: 'Description',
      // video: 'https://www.youtube.com/watch?v=K4TOrB7at0Y',
      files: {
        all: "./docs/Word Reading-Midpoint.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
    {
      id: 12,
      name: "Comprehension & Expressive Vocab",
      // description: 'Description',
      // video: 'https://www.youtube.com/watch?v=K4TOrB7at0Y',
      files: {
        all: "./docs/Comprehension & Expressive Vocab-Midpoint.pdf",
        // low: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // medium: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf',
        // high: 'https://www.smartcaptech.com/wp-content/uploads/sample.pdf'
      },
    },
  ];

  response = canned;

  return response;
}

export function getRecommendations(taskid) {
  return instance.get(`/tasks/${taskid}/recommendations`);
}

// /**
//  *
//  * @param {*} classid
//  * @returns
//  */
//  export function getAssessmentStep(classid) {
//   return instance.get(`classes/${classid}/qr`)
//     .then(response => {
//       return response.data;
//     })
// }
