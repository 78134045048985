import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Col } from "react-bootstrap";

import { whoami, getCountryGrades, getTeachers } from "../services/APIClient";

import "./LeftPanel.css";

function LeftPanel() {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [teacherRoles, setTeacherRoles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const whoamiResponse = await whoami();
        const { schoolid, id, name, additionalRoles, countryid } =
          whoamiResponse.data.stakeholder;

        setSchoolid(schoolid);
        setTeacherid(id);
        setName(name);
        setTeacherRoles(additionalRoles);

        const gradesResponse = await getCountryGrades(countryid);
        setGradeLevelOptions(gradesResponse);

        const teachersResponse = await getTeachers(schoolid);
        setTeachers(teachersResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const handleSENCOView = () => {
    navigate("/sencoresults/detail", {
      state: {
        teachers,
        name,
        gradeLevelOptions,
        teacherid,
        schoolid,
      },
    });
  };

  console.log(teacherid)

  return (
    <Col sm={2} className="left-panel mt-6">
      <nav className="side-nav">
        <ul className="nav-list">
          <li>
            <Link to="/masterdashboard">Home</Link>
          </li>
          <li>
            <Link to="/admindashboard">Admin</Link>
          </li>
          {/* <li>
            <Link to="/classadmindashboard">My Classes</Link>
          </li> */}
          <li>
            <Link to="/assessmentsdashboard">Assign Screener</Link>
          </li>
          <li>
            <Link to="/qrdashboard">Student QRs</Link>
          </li>
          <li>
            {teacherRoles?.includes("SENCO") ? (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  handleSENCOView();
                }}
              >
                Results
              </a>
            ) : (
              <Link to="/resultsdashboard">Results</Link>
            )}
          </li>
          {/* <li>
            <Link to="/customassessmentsdashboard">
              Create Assessment (Demo)
            </Link>
          </li> */}
          <li>
            <Link to="/sandbox">Sandbox</Link>
          </li>
        </ul>
      </nav>
    </Col>
  );
}

export default LeftPanel;
